import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Logo, Link } from '@components'
import { paths } from 'routes'
import { withTranslation, useTranslation } from 'react-i18next'

const PREFIX = 'forgotPassword'

const classes = {
	root: `${PREFIX}-root`,
	header: `${PREFIX}-header`,
	paper: `${PREFIX}-paper`,
	iconWrapper: `${PREFIX}-iconWrapper`,
	form: `${PREFIX}-form`,
	actionsContainer: `${PREFIX}-actionsContainer`,
	boldLink: `${PREFIX}-boldLink`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
	[`&.${classes.root}`]: {
		height: '100vh',
	},

	[`& .${classes.header}`]: {
		padding: 20,
	},

	[`& .${classes.paper}`]: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	[`& .${classes.iconWrapper}`]: {
		margin: 'auto',
		lineHeight: 1,
	},

	[`& .${classes.form}`]: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},

	[`& .${classes.actionsContainer}`]: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},

	[`& .${classes.boldLink}`]: {
		fontWeight: 'bold',
	},
}))

function Copyright() {
	const { t } = useTranslation()
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{t('forgotPassword.copyright')}
			<Link variant="body2" href={'https://www.dietahealth.com'}>
				{t('forgotPassword.dieta_health')}
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}

const auth0ErrorMapper = (errorPayload) => {
	const error = errorPayload && errorPayload.error
	let errorMessage = 'Authentication Failed!'
	if (error) {
		errorMessage = error.description
	}
	if (errorMessage === 'Global limit has been reached') {
		window.alert(
			'User Registration temporarily disabled',
			"We're experiencing a large amount of traffic and have put access enforcements in place at this time.  Sorry about that. Try again later"
		)
	}
	if (error && error.name) {
		if (error.code === 'Missing required parameters') {
			errorMessage = 'Invalid credentials'
		}
		if (error.code === 'a0.response.invalid') {
			errorMessage = 'Invalid credentials'
		}
		if (error.code === 'too_many_attempts') {
			errorMessage = error.description
		}
	}
	return errorMessage
}

class ForgotPassword extends Component {
	constructor(props) {
		super(props)
		this.emailRef = React.createRef()
	}

	state = {
		authError: null,
		loading: false,
	}

	static propTypes = {
		auth: PropTypes.shape({
			accessToken: PropTypes.string,
		}).isRequired,
		resetForgotPassword: PropTypes.func.isRequired,
	}

	static defaultProps = {
		auth: {
			accessToken: null,
		},
	}

	onPressResetPassword = async (e) => {
		console.log('onPressResetPassword')
		e.preventDefault()
		this.setState({
			loading: true,
		})
		const res = await this.props.resetForgotPassword(this.emailRef.value)
		if (res.err) {
			this.setState({
				errorMessage: 'Unable to reset password.',
			})
		} else {
			this.setState({
				emailSent: true,
			})
		}
		this.setState({
			loading: false,
		})
	}

	render() {
		const { t } = this.props
		return (
			<StyledGrid justifyContent="center" container component="main" className={classes.root}>
				<CssBaseline />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<div className={classes.paper}>
						<Grid container alignItems="center" spacing={2} direction="row">
							<Grid item className={classes.iconWrapper}>
								<Logo scale={1} />
							</Grid>
						</Grid>
						<Typography component="h1" variant="h5" className={classes.header}>
							{t('forgotPassword.title')}
						</Typography>
						{this.state.emailSent ? (
							<>
								<Typography component="h2" variant="subtitle1" gutterBottom>
									{t('forgotPassword.email_sent')}
								</Typography>
								<Typography component="h3" variant="subtitle2" classNam>
									{t('forgotPassword.email_sent_disclaimer')}
								</Typography>
							</>
						) : (
							<form
								className={classes.form}
								noValidate
								onSubmit={(e) => {
									this.onPressResetPassword(e)
								}}
							>
								{this.state.errorMessage ? (
									<Typography component="h2" variant="subtitle1">
										{t('forgotPassword.email_sent_error')}
									</Typography>
								) : (
									<></>
								)}
								<TextField
									inputRef={(ref) => (this.emailRef = ref)}
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label={t('forgotPassword.email')}
									name="email"
									autoComplete="email"
									autoFocus
								/>
								<Grid container spacing={3} className={classes.actionsContainer}>
									<Grid item xs={12}>
										<Button type="submit" fullWidth variant="contained">
											{t('forgotPassword.reset_password')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							className={classes.actionsContainer}
						>
							<Grid item xs>
								<Link to={paths.login} variant="body2" className={classes.boldLink}>
									{t('forgotPassword.login_already_have')}
								</Link>
							</Grid>
							<Grid item>
								<Link to={paths.signup} variant="body2" className={classes.boldLink}>
									{t('forgotPassword.signup')}
								</Link>
							</Grid>
						</Grid>
						<Box mt={5}>
							<Copyright />
						</Box>
					</div>
				</Grid>
			</StyledGrid>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(ForgotPassword)
